<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard v-if="playlist">
          <CCardBody class="p-4">
            <CButton
              color="danger"
              type="button"
              class="float-right"
              @click="deletePlaylist"
            >
              Delete playlist
            </CButton>

            <h1>{{ playlist.name }}</h1>

            <div class="clearfix"></div>

            <div
              class="mt-4"
              v-for="category in playlist.categories"
              :key="category.id"
            >
              <h2>{{ category.name }}</h2>

              <CRow>
                <CCol
                  md="2"
                  class=""
                  v-for="channel in category.channels"
                  :key="channel.id"
                >
                  <div class="px-3 py-4 channel border rounded my-3">
                    <div class=" ">
                      <CImg :src="channel.logo" block fluid class="mb-2" />
                      <div class="text-center mt-4">
                        <strong>{{ channel.name }}</strong>
                      </div>
                    </div>
                  </div>
                </CCol>
              </CRow>
            </div>
          </CCardBody>
          <!-- <CCardFooter> Standard Footer. </CCardFooter> -->
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
// import { mapState, mapGetters } from "vuex";
import playlists from "@/api/playlists";

export default {
  data() {
    return {
      playlist: null,
    };
  },
  //   computed: mapState({
  //     user: (state) => state.auth.user,
  //     ...mapGetters({
  //       authStatus: "auth/authStatus",
  //       isLoggedIn: "auth/isLoggedIn",
  //     }),
  //   }),
  mounted() {
    playlists
      .getPlaylist(this.$route.params.id)
      .then((data) => {
        this.playlist = data.data;
      })
      .catch(() => {});
  },

  methods: {
    deletePlaylist() {
      if (confirm("Are you sure you want to delete this playlist?")) {
        playlists
          .deletePlaylist(this.$route.params.id)
          .then(() => {
            this.$router.push({ path: "/playlists" });
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.channel {
  background: #eee;
}
</style>